
export default {
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    gridStyle() {
      let cols = 1
      if (this.$vuetify.breakpoint.lgAndUp && this.blok.lgColumns) {
        cols = this.blok.lgColumns
      } else if (this.$vuetify.breakpoint.mdOnly && this.blok.mdColumns) {
        cols = this.blok.mdColumns
      } else if (this.$vuetify.breakpoint.smOnly && this.blok.smColumns) {
        cols = this.blok.smColumns
      } else if (this.$vuetify.breakpoint.xsOnly && this.blok.xsColumns) {
        cols = this.blok.xsColumns
      }

      return {
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
      }
    },
  },
}
